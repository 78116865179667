.page_theme_violet-mono {
  /* Colors */

  /* Primary */
  --x-color--primary: #4c1d95;
  --x-color--primary-background: #f5f3ff;
  --x-color--primary-dark: #2e1065;
  --x-color--primary-light: #ede9fe;
  --x-color--primary-text: #ffffff;

  /* Secondary */
  --x-color--secondary: #a78bfa;
  --x-color--secondary-background: #f5f3ff;
  --x-color--secondary-dark: #6d28d9;
  --x-color--secondary-light: #ddd6fe;
  --x-color--secondary-text: #000000;

  /* Accent */
  --x-color--accent: #7c3aed;
  --x-color--accent-background: #f5f3ff;
  --x-color--accent-dark: #5b21b6;
  --x-color--accent-light: #c4b5fd;
  --x-color--accent-text: #ffffff;

  /* Neutral */
  --x-color--theme-neutral: #e5e5e5;
  --x-color--theme-neutral-background: #fafafa;
  --x-color--theme-neutral-dark: #a3a3a3;
  --x-color--theme-neutral-light: #f5f5f5;
  --x-color--theme-neutral-text: #000000;

  /* Outline */
  --x-focus-outline: #2e1065;

  /* Links */
  --x-link-color: #4c1d95;
  --x-link-color--focus: #2e1065;
  --x-link-color--hover: #2e1065;
  --x-link-color--visited: #4c1d95;

  --x-link-text-decoration: underline;

  /* Status */
  --x-color--info-default: #2563eb;
  --x-color--info-dark: #1e3a8a;
  --x-color--info-light: #dbeafe;

  --x-color--warning-default: #facc15;
  --x-color--warning-dark: #ca8a04;
  --x-color--warning-light: #fef9c3;

  --x-color--error-default: #dc2626;
  --x-color--error-dark: #7f1d1d;
  --x-color--error-light: #fee2e2;

  /* Text */
  --x-color--text-default: #171717;
  --x-color--text-light: #ffffff;
  --x-color--text-medium: #737373;

  /* Black and White */
  --x-color--theme-black: #000000;
  --x-color--theme-white: #ffffff;
}
