.page_theme_magenta-lime {
  /* Colors */

  /* Primary */
  --x-color--primary: #dc0073;
  --x-color--primary-background: #ffeaf5;
  --x-color--primary-dark: #7d0041;
  --x-color--primary-light: #ff97cd;
  --x-color--primary-text: #fafafa;

  /* Secondary */
  --x-color--secondary: #008bf8;
  --x-color--secondary-background: #ecf7ff;
  --x-color--secondary-dark: #00518f;
  --x-color--secondary-light: #b5dfff;
  --x-color--secondary-text: #0a0a0a;

  /* Accent */
  --x-color--accent: #04e762;
  --x-color--accent-background: #ecfff4;
  --x-color--accent-dark: #027431;
  --x-color--accent-light: #9efec6;
  --x-color--accent-text: #0a0a0a;

  /* Neutral */
  --x-color--theme-neutral: #d4d4d4;
  --x-color--theme-neutral-background: #fafafa;
  --x-color--theme-neutral-dark: #737373;
  --x-color--theme-neutral-light: #f5f5f5;
  --x-color--theme-neutral-text: #0a0a0a;

  /* Outline */
  --x-focus-outline: #1e3a8a;

  /* Links */
  --x-link-color: #2563eb;
  --x-link-color--focus: #1e3a8a;
  --x-link-color--hover: #1e3a8a;
  --x-link-color--visited: #2563eb;

  --x-link-text-decoration: underline;

  /* Status */
  --x-color--info-default: #2563eb;
  --x-color--info-dark: #1e3a8a;
  --x-color--info-light: #dbeafe;

  --x-color--warning-default: #facc15;
  --x-color--warning-dark: #ca8a04;
  --x-color--warning-light: #fef9c3;

  --x-color--error-default: #dc2626;
  --x-color--error-dark: #7f1d1d;
  --x-color--error-light: #fee2e2;

  /* Text */
  --x-color--text-default: #0a0a0a;
  --x-color--text-light: #fafafa;
  --x-color--text-medium: #737373;

  /* Black and White */
  --x-color--theme-black: #0a0a0a;
  --x-color--theme-white: #fafafa;
}
